/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import EStatementsEnrollmentForm from "~/components/forms/eStatementsEnrollment"

const eStatementsEnrollmentPage = () => {
  return (
    <Layout>
      <Seo title="Business eStatements Enrollment" />
      <section className="py-20 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex justify-center -mx-8">
            <div className="w-full md:w-3/4 px-8">
              <h1
                className="text-6xl text-primary mb-8">Business eStatements Enrollment</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8">
              <EStatementsEnrollmentForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default eStatementsEnrollmentPage