import {default as React} from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import moment from "moment"

const EStatementsEnrollmentForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const today = moment().format("YYYY-MM-DD")

  const schema = Yup.object({
    name: Yup.string().required("Account Owner's Name is Required"),
    email: Yup.string().email().required("Email is Required"),
    phone: Yup.string().required('Phone Number is required'),
    accounts: Yup.array().of(Yup.string()).required("Please fill at least one account number"),
    signature: Yup.string().required("Signature is Required"),
    date: Yup.date().required('Date is requiered'),
    terms: Yup.bool().oneOf([true], 'You must accept the terms of the agreement')
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    accounts: ['', '', '', ''],
    signature: "",
    date: today,
    terms: false
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const token = await executeRecaptcha("contact_form")
      try{
        const data = await fetch(`/api/recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        }).then(response => {
          if(!response.ok){
            throw new Error('Response from API call was not Ok')
          }
          return response.json()
        }).then(data => data)

        if(data.score > 0.5){
          //safe to submit the form
          try{
            await fetch(`/api/mailer`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ id: 'eStatements', ...values })
            }).then(response => {
              if(!response.ok){
                throw new Error('Could not submit form')
              }
              return response.json()
            }).then(data => data)
            //set thank you message
            resetForm()
          } catch(e){
            console.log(e) 
          }
        }
      }catch(e){
        console.log(e)
      } 
    }
  })

  return (
    <div>
      <form
        action="/"
        className="grid grid-cols-1 gap-4"
        onSubmit={handleSubmit}>
        <label
          htmlFor="name"
          className="block">
          <span className="text-primary font-heading text-xl">Account Owner's Name</span>
          <input
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="name"
            name="name"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.name && touched.name ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Account Owner's Name" />
            {touched.name && errors.name ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.name}</div>
            ) : null}
        </label>
        <label
          htmlFor="email"
          className="block">
          <span className="text-primary font-heading text-xl">Email Address</span>
          <input
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            id="email"
            name="email"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.email && touched.email ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Email" />
            {touched.email && errors.email ? (
              <div className="text-red-400 text-sm px-4 py-1">{errors.email}</div>
            ) : null}
        </label>
        <label
          htmlFor="phone"
          className="block">
          <span className="text-primary font-heading text-xl">Daytime Phone</span>
          <input
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            type="tel"
            id="phone"
            name="phone"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.phone && touched.phone ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Phone" />
            {touched.phone && errors.phone ? (
              <div className="text-red-400 text-sm px-4 py-1">{errors.phone}</div>
            ) : null}
        </label>
        <div
          className="grid grid-cols-2 gap-4">
          <label
            className="block">
            <span className="text-primary font-heading text-xl">Account Number One</span>
            <input
              value={values.accounts[0]}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              name="accounts[0]"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Account One" />
              {touched.accounts && errors.accounts ? (
                <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[0]}</div>
              ) : null}
          </label>
          <label
            className="block">
            <span className="text-primary font-heading text-xl">Account Number Two</span>
            <input
              value={values.accounts[1]}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              name="accounts[1]"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Account Two" />
              {touched.accounts && errors.accounts ? (
                <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[1]}</div>
              ) : null}
          </label>
          <label
            className="block">
            <span className="text-primary font-heading text-xl">Account Number Three</span>
            <input
              value={values.accounts[2]}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              name="accounts[2]"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Account Three" />
              {touched.accounts && errors.accounts ? (
                <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[2]}</div>
              ) : null}
          </label>
          <label
            className="block">
            <span className="text-primary font-heading text-xl">Account Number Four</span>
            <input
              value={values.accounts[3]}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              name="accounts[3]"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Account Four" />
              {touched.accounts && errors.accounts ? (
                <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[3]}</div>
              ) : null}
          </label>
        </div>
        <label
          className="block">
          <span className="text-primary font-heading text-xl">Terms &amp; Conditions</span><br />
          <input
            type="checkbox"
            name="terms"
            value={values.terms}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`mr-2 rounded-md ${errors.terms && touched.terms ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`} />
            <span>I have read the <a target="blank" rel="norefferer" href="/files/Business-eStatements-Agreement.pdf" className="text-blue-500">eStatement agreement</a> and accept the terms of the agreement</span>
            {touched.terms && errors.terms ? (
                <div className="text-red-400 text-sm px-4 py-1">{errors.terms}</div>
              ) : null}
         </label>
         <div
          className="grid grid-cols-2 gap-4">
          <label
            htmlFor="signature"
            className="block">
            <span className="text-primary font-heading text-xl">Signature</span>
            <input
              value={values.signature}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="signature"
              name="signature"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.signature && touched.signature ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Signature" />
              {touched.signature && errors.signature ? (
                <div className="text-red-400 text-sm px-4 py-0.5">{errors.signature}</div>
              ) : null}
          </label>
          <label
            htmlFor="date"
            className="block">
            <span className="text-primary font-heading text-xl">Date</span>
            <input
              value={values.date}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              id="date"
              name="date"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.date && touched.date ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              />
              {touched.date && errors.date ? (
                <div className="text-red-400 text-sm px-4 py-0.5">{errors.date}</div>
              ) : null}
          </label>
        </div>
        <div
          className="mt-4">
          <button
            disabled={!isValid || isSubmitting}
            className="btn btn-secondary"
            type="submit">Send Message</button>
        </div>
      </form>
    </div>
  )
}

export default EStatementsEnrollmentForm